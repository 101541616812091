<template lang="pug">
  .terms
    Breadcrumb(:current_path="{name: 'TermsAndConditions', title: 'Terms and conditions'}")

    .container.terms__container
      h1.title_2.terms__title(v-html="$t('pages.terms.title')")
      h2.title_3.terms__subtitle(v-html="$t('pages.terms.text')")

      .terms__content
        ul.terms__list
          li.terms__item(
            v-for="(item, index) in $t('pages.terms.list')"
            :key="index"
            v-html="item"
          )
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'

export default {
  name: 'TermsAndConditions',
  components: { Breadcrumb }
}
</script>
